import React, { useContext } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';

import BlogCard from '../../General/BlogCard/BlogCard'
import Section from '../../../styles/Section'
import { colors, fonts, media } from '../../../lib/style-utils'

const MainContainer = styled.div`
    width: 100%;
`

/* SectionExtended */
const SectionExtended = styled(Section)`
    position: relative;
    top: 0;
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    > div > div {
        display:flex;
        flex-direction:column;
        justify-content: space-between;
    }

    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
        margin: 0 auto;
    `}
`

const SectionArticles = styled(Section)`
    position: relative;
    top: 0;
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
        margin: 0 auto;
    `}
`

const PageContent = styled.div`
    background-color: ${colors.white};
    padding: 3rem 0 0 0;
    ${media.tablet`
        padding: 4rem 0;
    `}
`

const TitleLatest = styled.h3`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${fonts.Montserrat};
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
    align-self: center;
    width: 100%;
    margin-top: 0;
    padding: 0 1rem;
    ${media.desktop`
        padding: 0;
        margin-bottom: 2rem;
    `}
`

const BlogsBox = styled.div`
    padding: 1rem;

    &.cols-5{
        ${media.tablet`
            display: flex;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
        `}
        ${media.lgDesktop`
            justify-content: space-between;
        `}
    }

    ${media.desktop`
       padding: unset;
    `};
    
`

const LinkedArticles = ({linkedArticles, location, cols}) => {

        return (
            <PageContent>
                <SectionArticles>
                    <TitleLatest> {}<FormattedMessage id="ArticlesTitleHome"/></TitleLatest>
                    <BlogsBox 
                        className={`${cols == 5 ? 'cols-5' : 'grid grid-cols-12 gap-4 md:gap-6 xl:gap-8 2xl:gap-12'}`}
                    >
                        {
                            linkedArticles.map((article, i) => {
                                if (!article) return null
                                return (
                                    <BlogCard data={article} location={location} key={i} cols={cols} />
                                )
                            })
                        }
                    </BlogsBox>
                </SectionArticles>
            </PageContent>
        )
}

export default LinkedArticles