import React, {useState} from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import parse from 'html-react-parser'

import { fonts, colors, media } from '../../../lib/style-utils'
import Section from '../../../styles/Section'
import GlobalTextStyles from '../../General/Text/GlobalTextStyles'
import RevealOpacity from '../../Animation/RevealOpacity'
import RevealFadeTop from '../../Animation/RevealFadeTop'

const MainContainer = styled.div`
    width: 100%;
`

/* SectionExtended */
const SectionExtended = styled(Section)`
    position: relative;
    top: 0;
    padding-top: 0; 
    padding-bottom: 0;
    max-width: 100%;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    > div > div {
        display:flex;
        flex-direction:column;
        justify-content: space-between;
    }

    ${media.phablet`
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 2rem;
    `}
    ${media.desktop`
        width: 88%;
        max-width: 88%;
        margin: 0 auto;
    `}
`

const HeaderContainer = styled.div`
    width: 100%;
    background: ${colors.gradient};
`

const SectionText = styled.div`
    padding: 4rem 0 3rem 0;
    font-family: ${fonts.Montserrat};
    ${media.tablet`
        padding: 4rem 0 4rem 0;
    `};
    ${media.desktop`
        padding: 4rem 0 4rem 0;
    `};
`

const HeaderContentBlock = styled.div`
    display:flex;
    flex-direction:column;
    align-self: flex-start;
    ${media.tablet`
        flex-direction:row;
        align-self: flex-start;
    `}
`


const TextMain = styled.h1`
    display: flex;
    flex-direction: column;
    font-weight:800;
    font-size: 4rem;
    line-height: 3.5rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    color: white;
    margin-right:1rem;

    ${media.tablet`
        font-size: 4rem;
        line-height: 3rem;
    `};
    ${media.desktop`
        font-size: 4rem;
        line-height: 4rem;
        margin-bottom: 1rem;
    `};

    ${media.midDesktop`
        font-size: 5rem;
        line-height: 4rem;
    `};
`

const TitleLatest = styled.h3`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${fonts.Montserrat};
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
    align-self: center;
    width: 100%;
    margin-top: 0;
    padding: 0 1rem;
    ${media.desktop`
        padding: 0;
        margin-bottom: 2rem;
    `}
`

const TextIntro = styled.div`
    padding: 1.5rem 0 0 0;
    order: 1;
    p {
        font-size: 0.8rem;
        line-height:1.3rem;
    }

    ${media.tablet`
        order: 2;
        max-width: 60%;
        float: right;
    `}
    ${media.desktop`
        max-width: 60%;
        float: right;
    `}
    ${media.lgDesktop`
        max-width: 50%;
        float: right;
    `}
`

const PrimaryButtonWrap = styled.div`
    width: 100%;
    display:flex;
    justify-content:flex-start;
    align-self: flex-end;
    order: 2;
    margin-top:2rem;
    ${media.tablet`
        order: 1;
        margin-top:unset;
    `};
`

const PrimaryButton = styled(AniLink)`
    text-align: center;
    display: flex;
    background-color: white;
    border-radius: 0.2rem;
    font-size: 1rem;
    color: ${colors.dark_grey};
    padding: 0.6rem 2rem;
    transition: all 0.3s ease;
    ${media.tablet`
        background: ${colors.white};
        color: ${colors.dark_grey};
        transition: all 0.3s ease;
        width: 12rem;
        justify-content: center;
    `};
    :hover{
        transition: background 0.3s ease;
        border-color: orange;
        color: orange;
    }
`

const HeaderSection = ({data, url, pageData}) => {
        return (
            <MainContainer>
                <HeaderContainer>
                    <SectionText>
                        <SectionExtended>
                            <RevealOpacity>                             
                                    <TextMain>
                                        {pageData.title}
                                    </TextMain>
                                <HeaderContentBlock>
                                   <TextIntro>
                                       <GlobalTextStyles>
                                            {parse(pageData.pageIntro)}
                                       </GlobalTextStyles>
                                   </TextIntro>
                                   <PrimaryButtonWrap>
                                        <PrimaryButton to={`${url}contact`} cover direction="right" duration={1.2} bg="orange"><FormattedMessage id="servicesCardButton"/></PrimaryButton>
                                    </PrimaryButtonWrap>
                                </HeaderContentBlock>
                            </RevealOpacity>
                        </SectionExtended>
                    </SectionText>
                </HeaderContainer>
            </MainContainer>
        )
}

export default HeaderSection